<!-- =========================================================================================
    File Name: CardStatistics.vue
    Description: Statistics Card
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="page">
    <!-- swiper -->
    <swiper :dir="$vs.rtl ? 'rtl' : 'ltr'" :key="$vs.rtl">
      <div class="parallax-bg" slot="parallax-bg" data-swiper-parallax="-23%"></div>
      <swiper-slide class="swiper-slide details">
        <div class="content-slider">
          <div class="title" data-swiper-parallax="-100">Détails de la commande : <br>
            <h2><b>{{titreCommande}}</b></h2></div>

        </div>
      </swiper-slide>
    </swiper>
    <div class="container">
      <!-- Tableau -->
      <vx-card action-buttons @refresh="closeCardAnimationDemo" title="Commande" class="mt-10">
        <vs-button  class="mb-4 md:mb-0 float-right" @click="downloadWithAxios(commandes[0].invoice.filename)">Facture</vs-button><br><br>
        <vs-table :data="commandes"  style="margin-top: 30px" >
          <template slot-scope="{data}">
            <vs-tr v-if="data[0].statut">
              <vs-td class="headTab">
                Statut
              </vs-td>
              <vs-td :data="data[0].statut" class="status">
                <span :class=" data[0].statut">{{data[0].statut | statutConvert}}</span>
              </vs-td>
            </vs-tr>
            <vs-tr>
              <vs-td class="headTab">
                Réference
              </vs-td>
              <vs-td :data="data[0].reference">
                {{data[0].reference}}
              </vs-td>
            </vs-tr>
            <vs-tr v-if="data[0].dateCreation">
              <vs-td class="headTab">
                Date de création
              </vs-td>
              <vs-td :data="data[0].dateCreation">
                {{data[0].dateCreation | formDate}}
              </vs-td>
            </vs-tr>
            <vs-tr v-if="data[0].datePriseCharge">
              <vs-td class="headTab">
                Date de prise en charge
              </vs-td>
              <vs-td :data="data[0].datePriseCharge">
                {{data[0].datePriseCharge | formDate}}
              </vs-td>
            </vs-tr>
            <vs-tr v-if="data[0].dateCloture">
              <vs-td class="headTab">
                Date de clôture
              </vs-td>
              <vs-td :data="data[0].dateCloture">
                {{data[0].dateCloture | formDate}}
              </vs-td>
            </vs-tr>
            <vs-tr v-if="data[0].formule">
              <vs-td class="headTab">
                Formule
              </vs-td>
              <vs-td :data="data[0].formule">
                {{data[0].formule}}
              </vs-td>
            </vs-tr>
            <vs-tr v-if="data[0].offreCommande !== undefined">
              <vs-td class="headTab">
                Promotion
              </vs-td>
              <vs-td :data="data[0].offreCommande">
                <vs-td :data="data[0].offreCommande.libelle">
                  {{data[0].offreCommande.libelle}}
                </vs-td>
              </vs-td>
            </vs-tr>
            <vs-tr v-if="data[0].fonctionnement">
              <vs-td class="headTab">
                Fonctionnement
              </vs-td>
              <vs-td :data="data[0].fonctionnement">
                {{data[0].fonctionnement}}
              </vs-td>
            </vs-tr>
            <vs-tr v-if="data[0].criteres">
              <vs-td class="headTab">
                Critères
              </vs-td>
              <vs-td :data="data[0].criteres">
                  <vs-checkbox vs-value="Maison" required v-model="data[0].criteres"> Maison </vs-checkbox>
                  <vs-checkbox vs-value="Terrain" required="'required'" v-model="data[0].criteres">Terrain</vs-checkbox>
                  <vs-checkbox vs-value="Appartement" required="'required'" v-model="data[0].criteres">Appartement</vs-checkbox>
                  <vs-checkbox vs-value="Loft" required="'required'" v-model="data[0].criteres">Loft</vs-checkbox>
              </vs-td>
            </vs-tr>
            <vs-tr v-if="secteur">
              <vs-td class="headTab">
                Secteur
              </vs-td>
              <vs-td :data="secteur">
                <p class="wrap">{{secteur}}</p>
              </vs-td>
            </vs-tr>
            <vs-tr v-if="data[0].prestataire">
              <vs-td class="headTab">
                Prestataire
              </vs-td>
              <vs-td :data="data[0].prestataire">
                {{data[0].prestataire}}
              </vs-td>
            </vs-tr>
            <vs-tr v-if="data[0].employee">
              <vs-td class="headTab">
                Téleconseiller
              </vs-td>
              <vs-td :data="data[0].prestataire">
                {{data[0].employee.firstName}} {{data[0].employee.lastName}}
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vx-card>
      <vx-card action-buttons @refresh="closeCardAnimationDemo"  title="Rendez-vous" class="mt-10">
        <vx-card action-buttons @refresh="closeCardAnimationDemo" class="mb-10">
          <vs-table :data="RendezVous">

            <template slot="thead">

              <vs-th>Id</vs-th>
              <vs-th>Date début</vs-th>
              <vs-th>Statut</vs-th>
              <vs-th>Lien</vs-th>

            </template>

            <template slot-scope="{data}">
              <vs-tr :state="data[indextr].statut == 'Réalisé' ? 'success': data[indextr].statut == 'Annulé' ? 'warning':null" :key="indextr" v-for="(tr, indextr) in data">

                <vs-td :data="data[indextr].id">
                  {{ data[indextr].id }}
                </vs-td>

                <vs-td :data="data[indextr].dateDebut">
                  {{ data[indextr].dateDebut }}
                </vs-td>

                <vs-td :data="data[indextr].statut">
                  {{ data[indextr].statut }}
                </vs-td>
                <vs-td :data="data[indextr].id">
                  <a :href="'/rendez-vous/'+data[indextr].id" target="_blank" > <feather-icon svgClasses="w-4 h-4" icon="ExternalLinkIcon" class="mr-2"/></a>
                </vs-td>
              </vs-tr>
            </template>

          </vs-table>
        </vx-card>
        <vs-table :data="commandes"  style="margin-top: 30px" >
          <template slot-scope="{data}">
            <vs-tr v-if="data[0].nbRdv">
              <vs-td class="headTab">
                Nombre de rendez-vous
              </vs-td>
              <vs-td :data="data[0].nbRdv">
                {{data[0].nbRdv}}
              </vs-td>
            </vs-tr>
            <vs-tr>
              <vs-td class="headTab">
                Nombre de rendez-vous recrédités + commandés
              </vs-td>
              <vs-td :data="data[0].nbRdsRecreditesCommandes">
                {{data[0].nbRdsRecreditesCommandes}}
              </vs-td>
            </vs-tr>
            <vs-tr v-if="data[0].nbRdsRealises">
              <vs-td class="headTab">
                Nombre de rendez-vous réalisés
              </vs-td>
              <vs-td :data="data[0].nbRdsRealises">
                {{data[0].nbRdsRealises}}
              </vs-td>
            </vs-tr>
            <vs-tr v-if="data[0].nbRdvPris">
              <vs-td class="headTab">
                Nombre de rendez-vous Pris
              </vs-td>
              <vs-td :data="data[0].nbRdvPris">
                {{data[0].nbRdvPris}}
              </vs-td>
            </vs-tr>
            <vs-tr v-if="data[0].annulation">
              <vs-td class="headTab">
                Nombre de rendez-vous annulés
              </vs-td>
              <vs-td :data="data[0].annulation">
                {{data[0].annulation}}
              </vs-td>
            </vs-tr>
            <vs-tr v-if="data[0].nbRdvRestants">
              <vs-td class="headTab">
                Nombre de rendez-vous Restants
              </vs-td>
              <vs-td :data="data[0].nbRdvRestants">
                {{data[0].nbRdvRestants}}
              </vs-td>
            </vs-tr>
            <vs-tr v-if="data[0].nbRdsRecredites">
              <vs-td class="headTab">
                Nombre de rendez-vous Recredités
              </vs-td>
              <vs-td :data="data[0].nbRdsRecredites">
                {{data[0].nbRdsRecredites}}
              </vs-td>
            </vs-tr>
            <vs-tr>
              <vs-td class="headTab">
                Nombre de mandats réalisés
              </vs-td>
              <vs-td :data="data[0].mandats">
                {{data[0].mandats}}
              </vs-td>
            </vs-tr>
            <vs-tr v-if="data[0].dateDernierRdv">
              <vs-td class="headTab">
                Date du dérnier rendez-vous
              </vs-td>
              <vs-td :data="data[0].dateDernierRdv">
                {{data[0].dateDernierRdv | formDate}}
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vx-card>
        <vx-card action-buttons @refresh="closeCardAnimationDemo"  title="Informations supplémentaires" class="mt-10">
          <vs-table :data="commandes"  style="margin-top: 30px" >
            <template slot-scope="{data}">
              <vs-tr>
                <vs-td class="headTab">
                  Résiliation
                </vs-td>
                <vs-td :data="data[0].resilie">
                  {{data[0].resilie}}
                </vs-td>
              </vs-tr>
              <vs-tr>
                <vs-td class="headTab">
                  Date de résiliation
                </vs-td>
                <vs-td :data="data[0].dateResiliation">
                 {{data[0].dateResiliation}}
                </vs-td>
              </vs-tr>
              <vs-tr>
                <vs-td class="headTab">
                  Date mail
                </vs-td>
                <vs-td :data="data[0].dateMail">
                  {{data[0].dateMail}}
                </vs-td>

              </vs-tr>
              <vs-tr>
                <vs-td class="headTab">
                  Réponse Mail
                </vs-td>
                <vs-td :data="data[0].reponseMail">
               {{data[0].reponseMail}}
                </vs-td>
              </vs-tr>
              <vs-tr>
                <vs-td class="headTab">
                  Numéros à exclure
                </vs-td>
                <vs-td :data="data[0].numeros">
                <textarea
                  class="w-full mt-6 textarea"
                  type="text"
                  v-model="data[0].numeros"
                />
                </vs-td>
              </vs-tr>
            <vs-tr>
              <vs-td class="headTab">
                Commentaire
              </vs-td>
              <vs-td >
                <textarea
                  type="text"
                  v-model="data[0].commentaire"
                  class="w-full mt-6 textarea"
                />
              </vs-td>
            </vs-tr>

          </template>
        </vs-table>

          <br>
          <div class="text-right">
            <vs-button @click="popupActive=true" class="mr-3 mb-2" v-if="showValider">Valider</vs-button>
            <vs-button @click="Update()" class="mr-3 mb-2" type="border">Mettre à jour</vs-button>
          </div>
      </vx-card>
      <div class="vx-col w-full mb-base mt-20">
        <vx-card title="Historique de la commande" @refresh="closeCardAnimationDemo" action-buttons>
          <vx-timeline :data="timelineData" />
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import VxTimeline from '@/components/timeline/VxTimeline';
import axios from '../../../axios.js';
import { colors } from '../../../../themeConfig';
import router from '../../../router';

export default {
  components: {
    swiper,
    swiperSlide,
    VxTimeline,
  },
  props: ['id'],
  filters: {
    formDate(value) {
      if (value) {
        return moment(String(value)).format('DD/MM/YYYY HH:mm:ss');
      }
    },
    statutConvert(value) {
      switch (value) {
        case 'EP':
          return 'En attente de paiement';
        case 'EA':
          return 'En attente';
        case 'SB':
          return 'Suspendue';
        case 'EC':
          return 'En cours';
        case 'CLO':
          return 'Cloturée';
        case 'NR':
          return 'Non renseigné';
        case 'RF':
          return 'Refusée';
        default:
          return 'En cours';
      }
    },
  },
  data() {
    return {
      // Area charts
      secteur: [],
      enAttente: '',
      colors,
      Suspendus: '',
      enCours: '',
      Clotures: '',
      commandes: '',
      titreCommande: '',
      timelineData: '',
      RendezVous: '',
    };
  },
  methods: {
    Update() {
      let data = {};
      data = {
        commentaire: this.commandes[0].commentaire,
        criteres: this.commandes[0].criteres,
        numeros: this.commandes[0].numeros,

      };
      axios.put(`api/commandes/${this.id}`, data, {
        headers: {
          Accept: 'application/ld+json',
          'Content-Type': 'application/ld+json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      }).then((response) => {
        if (response.status == 200) {
          this.$vs.notify({
            title: 'Succès',
            text: 'La commande a été mise à jour avec succès',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success',
            time: 5000,
          });
        }
        // router.push("/admin/commandes")
        this.getHistorique();
      }).catch((error) => {
        this.$vs.notify({
          title: 'Erreur',
          text: this.$parseError(error).message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
          time: 4000,
        });
      });
    },
    closeCardAnimationDemo(card) {
      card.removeRefreshAnimation(3000);
    },
    getHistorique() {
      const colors = ['primary', 'warning', 'danger', 'success', 'blue', 'yellow'];
      // Historique des actions
      axios.get(`/api/historique_actions?commande=${this.id}&order[dateAction]=desc`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      })
        .then((response) => {
          let oneLine = {};
          const timeline = [];
          const { data } = response;
          let i = (response.data).length;
          data.forEach((value) => {
            oneLine = {
              color: colors[Math.floor(Math.random() * colors.length)],
              icon: value.libelle == 'Validation' ? 'CheckCircleIcon' : 'EditIcon',
              title: value.libelle,
              desc: `${value.text}\n \nPar ${value.who}`,
              time: value.dateAction,
            };
            if (value.libelle == 'Mise à jour') {
              oneLine.title = `${value.libelle} ${i}`;
              i--;
            }
            timeline.push(oneLine);
          });
          this.timelineData = timeline;
        })
        .catch(() => {
          this.$toasted.show('Une erreur est survenue! Veuillez actualiser la page.').goAway(1500);
        });
    },
    forceFileDownload(response) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'facture.pdf'); // or any other extension
      document.body.appendChild(link);
      link.click();
    },
    downloadWithAxios(file) {
      axios({
        method: 'get',
        url: `/api/file/stream/attachment/${file}`,
        responseType: 'arraybuffer',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      })
        .then((response) => {
          this.forceFileDownload(response);
        })
        .catch((error) => this.$toasted.show(error).goAway(1500));
    },
    details(id) {
      router.push('details', id);
    },
    getRdvs() {
      axios.get(`/api/rendez_vouses?commande=${this.id}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      })
        .then((response) => {
          this.RendezVous = response.data;
        })
        .catch(() => {
          this.$toasted.show('Une erreur est survenue! Veuillez actualiser la page.').goAway(1500);
        });
    },
  },
  created() {
    axios.get(`/api/payment/invoice/update/${this.id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }).then(() => {
      // Les commandes
      let arraySecteur = '';
      const regexNumbers = new RegExp('^[0-9]+$');
      axios.get(`/api/commandes/${this.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      })
        .then((firstResponse) => {
          // let arrayCodesPostaux = []
          this.commandes = [firstResponse.data];
          this.titreCommande = firstResponse.data.reference;
          // Les anciennes commandes importées par le fichier excel donc elles contiennent le champs secteur
          if (firstResponse.data.secteur) {
            const arrayCodes = (firstResponse.data.secteur).split('/');
            for (const prop in arrayCodes) {
              const city = arrayCodes[prop].trim();
              if (city.length > 0) {
                // Si c'est un code postal
                if (city.match(regexNumbers)) {
                  axios.get(`/api/cities?codePostal=${city}`, {
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                    },
                  }).then((secondResponse) => {
                    if (secondResponse.data.length > 0) {
                      secondResponse.data.forEach((value) => {
                        arraySecteur += `\n${value.name} ${value.codePostal} , ${value.population} habitants`;
                        // arrayCodesPostaux.push({"value": value})
                      });
                    } else {
                      arraySecteur += `\n${city}`;
                    }
                    this.secteur = arraySecteur;
                  });
                } else {
                  let codePostal = city;
                  codePostal = codePostal.replace(/\D+/g, '');
                  let url = `/api/cities?name=${city.replace(/[0-9]+/, '').replace(/^\s/, '')}`;
                  if (codePostal.length > 0) {
                    url = `${url.replace(/\s$/, '')}&codePostal=${codePostal}`;
                  }
                  axios.get(url, {
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                    },
                  }).then((response) => {
                    if (response.data.length > 0) {
                      response.data.forEach((value) => {
                        arraySecteur += `\n${value.name} ${value.codePostal} , ${value.population} habitants`;
                        // arrayCodesPostaux.push({"value": value})
                      });
                    } else {
                      arraySecteur += `\n${city}`;
                    }
                    this.secteur = arraySecteur;
                  });
                }
              }
            }
            // this.secteur = arraySecteur;
          } else {
            // Les nouvelles commandes, recherche par code postal + code insee
            const arrayCodes = (firstResponse.data.codesInsee).split('/');
            for (const q in arrayCodes) {
              if ((arrayCodes[q]).trim().length > 0) {
                const code = arrayCodes[q].split('-');
                axios.get(`/api/cities?code=${code[0].trim()}&codePostal=${code[1].trim()}`, {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                  },
                }).then((response) => {
                  if (response.data[0]) {
                    arraySecteur += `\n${response.data[0].name} ${response.data[0].codePostal} , ${response.data[0].population} habitants`;
                    this.secteur = arraySecteur;
                  }
                });
              }
            }
          }
        })
        .catch((error) => {
          if (error.response !== null) {
            if (error.response) {
              this.$vs.notify({
                title: 'Erreur',
                text: "Vous n'êtes pas autorisé à accéder à cette commande",
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'warning',
                time: 4000,
              });
              router.push('/historique');
            }
          } else {
            this.$toasted.show('Une erreur est survenue! Veuillez actualiser la page.').goAway(1500);
          }
        });

      this.getHistorique();
      this.getRdvs();
    });
  },
};
</script>
<style>
  .vs-con-table .vs-con-tbody .con-vs-checkbox {
    justify-content: flex-start !important;
  }
  .swiper-slide.details {
    background: url("../../../assets/images/Slider/details.png");
    background-size: cover;
  }

</style>
